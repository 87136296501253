import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import DstButton from "../../components/DstButton/DstButton";
import PictoContent from "../../components/Pictocontent/PictoContent";
import { Helmet } from "react-helmet";
import "./PorteBlinde.scss";

const PorteBlinde = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet>
        <title>Porte Blindée - Serrurerie Guy Môquet Paris 17</title>
        <meta
          name="description"
          content="Découvrez nos portes blindées de haute sécurité et nos services de blindage de portes pour protéger votre maison ou appartement. Demandez un devis gratuit dès maintenant."
        />
        <link
          rel="canonical"
          href="https://serrurerie-guymoquet.fr/porte-blinde/"
        />
      </Helmet>
      <section className="container my-5">
        <div className="container-fluid">
          <Row className="d-flex flex-column-reverse flex-md-row">
            <Col className="align-self-center text-center text-md-start">
              <img
                src="../../assets/images/blindage-porte.webp"
                alt="porte blindé"
                className="img-fluid"
              />
              <div className="mt-3 d-md-none">
                Pour plus de renseignement : 
                <Link className="orange-link " to={"/contact"}>
                  contactez nous
                </Link>
              </div>
            </Col>
            <Col md="8">
              <h2 className="line-title">Blindage de portes </h2>
              <h3>Porte blindée de haute sécurité</h3>
              <p>
                Vous souhaitez installer une porte blindée pour plus de sécurité
                ou ajouter un blindage via une plaque de tôle en acier sur votre
                porte blindée ? Nos experts en serrurerie sauront vous
                conseiller en vous proposant une{" "}
                <strong>porte blindée certifiée A2P</strong> de haute sécurité
                et vous l'installer.
              </p>
              <p>
                Vous pourrez ajouter à cela une{" "}
                <strong>serrure multipoints</strong> de toutes les marques les
                plus sécurisées telle que Fichet, Vachette ou Heracles afin de
                vous garantir plus de sécurité contre une tentative d’effraction
                ou de cambriolage. Après un audit réalisé à votre domicile, nous
                vous orienterons vers la solution la plus convenable selon vos
                besoins. Une estimation gratuite sous forme de devis vous sera
                remise.
              </p>

              <h3 className="mt-4">
                Picard Serrures est le n°1 de la porte blindée en France.
              </h3>
              <p>
                Fabrication française depuis 1720. L'usine est située en
                Feuquières-en-vimeu dans le département de la Somme. Depuis plus
                de 300 ans, elle conçoit, fabrique et commercialise des serrures
                de haute sécurité certifiées et des portes d’entrée blindées
                sur-mesure.
              </p>
              <Link className="orange-link d-none d-md-block" to={"/contact"}>
                Pour plus de renseignement, contactez nous
              </Link>
            </Col>
          </Row>
        </div>
        <div className=" my-5">
          <Row className="d-flex">
            <Col
              md="6"
              className="d-flex flex-column justify-content-around align-item-center"
            >
              <div>
                <h3 className="text-center">Porte diamant</h3>
                <PictoContent
                  image="../../assets/icons/icon-diamant.svg"
                  title=""
                  description={
                    <p className="ms-3">
                      Certification A2P BP1 <br />
                      Fermeture 5 points <br />
                      Clés incopiables <br />
                      Isolation phonique et thermique <br />
                      Coupe feu <br />
                    </p>
                  }
                  dFlex
                  centerVerticaly
                />
              </div>
              <div>
                <h3 className="mt-4 text-center">Bloc blindage</h3>
                <PictoContent
                  image="../../assets/icons/icon-blindage.svg"
                  title=""
                  description={
                    <p className="ms-3">
                      <strong>Blindage sur porte existante</strong> <br />
                      Certification délivrée par la CNPP
                    </p>
                  }
                  dFlex
                  centerVerticaly
                />
              </div>
            </Col>
            <Col className="align-self-center text-center mt-3 mt-md-0">
              <img
                src="../../assets/images/porte-blinde.webp"
                alt="porte blindé"
                className="img-fluid"
              />
            </Col>
          </Row>
        </div>
        <div className="text-center mt-4">
          <DstButton
            value="Je souhaite blinder ma porte"
            clickFunction={() => navigate("/contact/")}
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default PorteBlinde;
