import React from "react";
import { Helmet } from "react-helmet";

const MentionLegales = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Mentions légales - Serrurerie Guy Môquet Paris 17</title>
        <meta
          name="description"
          content="Retrouvez les informations légales du site Serrurerie Guy Môquet à Paris 17. Propriétaire du site, hébergeur, données personnelles et conditions d'utilisation."
        />
        <link
          rel="canonical"
          href="https://serrurerie-guymoquet.fr/mentions-legales/"
        />
      </Helmet>
      <section className="container my-5">
        <div className="container-fluid">
          <h1>Mentions légales</h1>
          <h2 className="mb-2 mt-4">Présentation du site :</h2>
          Le site web Serrurerie-guy-moquet.com est la propriété de la société
          Serrurerie Guy Moquet, dont le siège social est situé au 17 Rue de La
          Jonquière, 75017 Paris. <br />
          Le directeur de la publication et responsable de la rédaction est
          Stephane Baroukh.
          <h2 className="mb-2 mt-4">Hébergeur du site :</h2>
          Nom de l'hébergeur : OVH <br />
          Adresse : 2 rue Kellermann, 59100 Roubaix, France <br />
          Téléphone : 1007 (numéro gratuit en France) <br />
          Email : support@ovh.com <br />
          <h2 className="mb-2 mt-4">
            Conditions générales d'utilisation du site :
          </h2>
          En accédant à ce site, vous acceptez les conditions générales
          d'utilisation énoncées ci-dessous. Si vous n'acceptez pas ces
          conditions, veuillez ne pas utiliser ce site. Les informations
          contenues sur ce site sont fournies à titre informatif uniquement et
          peuvent être modifiées sans préavis.
          <h2 className="mb-2 mt-4">Propriété intellectuelle :</h2>
          L'ensemble des éléments (textes, logos, images, etc.) contenus sur ce
          site sont protégés par le droit d'auteur et la propriété
          intellectuelle. Toute reproduction, même partielle, est interdite sans
          l'autorisation préalable de Serrurerie Guy Moquet.
          <h2 className="mb-2 mt-4">Données personnelles :</h2>
          Serrurerie Guy Moquet s'engage à respecter la confidentialité des
          données personnelles collectées sur ce site et à les traiter dans le
          respect des dispositions légales en vigueur.
          <h2 className="mb-2 mt-4">
            Loi applicable et juridiction compétente :
          </h2>
          Les présentes mentions légales sont soumises au droit français. Tout
          litige relatif à l'interprétation ou l'exécution de ces mentions sera
          de la compétence exclusive des tribunaux français.
        </div>
      </section>
    </React.Fragment>
  );
};

export default MentionLegales;
