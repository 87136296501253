import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Hamburger } from "./images/hamburger.svg";
import { ReactComponent as Cross } from "./images/plus.svg";
import Brand from "./images/serrurier-guy-moquet.webp";
import "./Navbar.scss";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar" id="nav">
      <div className="container">
        <div className="logo">
          <NavLink to="/">
            <img
              src={Brand}
              alt="Logo serrurerie guy moquet"
              className="py-2"
            />
          </NavLink>
        </div>
        <div
          className={`menu-icon  ${showNavbar && "active"}  order-2`}
          onClick={handleShowNavbar}
        >
          <span className="hamburger">
            <Hamburger />
          </span>
          <span className="cross">
            <Cross />
          </span>
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li onClick={handleShowNavbar}>
              <NavLink to="/">Accueil</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/depannage-serrurerie/">
                Dépannage serrurerie
              </NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/reproduction-cle/">Reproduction Clé</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/prestations/">Prestations</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <NavLink to="/contact/">Devis gratuit</NavLink>
            </li>
            <li onClick={handleShowNavbar}>
              <a
                href="tel:+33142289944"
                className="d-sm-none text-decoration-none"
              >
                <span className="gray-font">Tel : 01 42 28 99 44 </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="order-1 d-none d-sm-flex">
          <span className="white-font">
            <NavLink to="/contact" className="text-decoration-none">
              {" "}
              Contact :
            </NavLink>{" "}
            <a href="tel:+33142289944" className=" text-decoration-none">
              <span className="orange-font">01 42 28 99 44 </span>
            </a>
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
