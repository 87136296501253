import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import DstButton from "../../components/DstButton/DstButton";

const PorteHall = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet>
        <title>Porte de Hall - Serrurerie Guy Môquet Paris</title>
        <meta
          name="description"
          content="Découvrez nos services d'installation de portes de hall en aluminium ou en acier pour bâtiments résidentiels et commerciaux. Contactez-nous dès maintenant pour plus d'informations."
        />
        <link
          rel="canonical"
          href="https://serrurerie-guymoquet.fr/porte-hall/"
        />
      </Helmet>
      <section className="container my-5">
        <div className="container-fluid">
          <Row className="d-flex flex-column-reverse flex-md-row">
            <Col className="align-self-center text-center text-md-start">
              <div>
                <img
                  src="../../assets/images/porte-hall.webp"
                  alt="porte blindé"
                  className="img-fluid"
                />
              </div>
              <DstButton
                btnClass="mt-3 d-md-none"
                value="Contactez-nous"
                clickFunction={() => navigate("/contact/")}
              />
            </Col>
            <Col md="8">
              <h2 className="line-title">Porte de hall</h2>
              <p>
                Les portes d'entrée de votre bâtiment peuvent être en{" "}
                <strong>aluminium</strong> ou en <strong>acier</strong> selon
                vos besoins.
              </p>
              <p>
                Les portes en <strong>aluminium</strong> sont principalement
                installées dans des bâtiments résidentiels où l'esthétique est
                plus importante que la sécurité. Certaines portes sont isolées,
                ce qui vous permet d'économiser de l'énergie et d'améliorer le
                confort dans le hall de l'immeuble.
              </p>
              <p>
                Les ventouses et les ferme-portes étant intégrés à la structure,
                les portes permettent l'intégration de tout type de{" "}
                <strong>contrôle d'accès</strong>. <br />
                Disponible dans une variété de couleurs RAL ou anodisé.
                Différents types de verre sont recommandés :{" "}
                <strong>antivol (44² ou 55²)</strong>,{" "}
                <strong>double vitrage</strong>, etc.
              </p>
              <p>
                Les portes des halls peuvent être équipées de poignées{" "}
                <strong>demi-lune</strong> peintes ou de poignées en{" "}
                <strong>acier inoxydable</strong>. Les boutons de sortie peuvent
                également être insérés sur les cadres de porte. Les réservations
                pour les <strong>interphones</strong> peuvent également être
                conseillées.
              </p>

              <DstButton
                btnClass="mt-3 d-none d-md-block"
                value="Contactez-nous"
                clickFunction={() => navigate("/contact/")}
              />
            </Col>
          </Row>
        </div>
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <h2 className="line-title">Boîtes aux lettres</h2>
          <p>
            La <strong>serrurerie Guy Môquet</strong> vous recommande
            l'installation d'une batterie de <strong>boîte aux lettres</strong>.{" "}
            <br />
            Nous nous déplaçons en Île-de-France au service des{" "}
            <strong>particuliers</strong>, des <strong>professionnels</strong>{" "}
            et des <strong>syndics de copropriété</strong>. <br />
            Peu importe le style de <strong>boîte aux lettres</strong> que vous
            souhaitez, nous ferons de notre mieux pour vous satisfaire. Nous
            nous adaptons à la configuration de votre bâtiment.
          </p>
          <p className="mt-2 text-md-center">
            Vous pouvez faire appel à notre équipe pour installer une{" "}
            <strong>boîte aux lettres</strong> entièrement intégrée pour un
            revêtement mural parfait. <br />
            Pour plus de sécurité, nous proposons des{" "}
            <strong>boîtes aux lettres antivol</strong>.
          </p>
          <div className="text-center">
            <img
              src="../../assets/images/boite-lettre.webp"
              alt="porte blindé"
              className="img-fluid"
            />
          </div>
          <p className="mt-2 text-md-center">
            Vous pouvez 
            <Link className="orange-link" to={"/contact"}>
              faire appel à notre équipe
            </Link>
             pour installer une boîte aux lettres entièrement intégrée pour un
            revêtement mural parfait.
            <br />
            Pour plus de sécurité, nous proposons des boîtes aux lettres
            antivol.
          </p>
        </div>
        <div className="text-center mt-4">
          <DstButton
            value="Contactez-nous"
            clickFunction={() => navigate("/contact/")}
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default PorteHall;
