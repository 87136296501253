import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Facebook from "../DstIcons/Facebook";
import Instagram from "../DstIcons/Instagram";
import Linkedin from "../DstIcons/Linkedin";
import Mail from "../DstIcons/Mail";
import MapAdress from "../DstIcons/MapAdress";
import Tel from "../DstIcons/Tel";
import TikTok from "../DstIcons/TikTok";
import "./Footer.scss";

const year = new Date().getFullYear();

interface IconLinkProps {
  link: string;
  icon: React.ReactNode;
  text: string;
}

const IconLink = ({ link, icon, text }: IconLinkProps) => (
  <a href={link} target="_blank" rel="noreferrer" className="menu-list">
    {icon}
    {text}
  </a>
);

const menuItems = [
  { link: "/depannage-serrurerie", text: "Ouverture de porte" },
  { link: "/pose-serrure", text: "Changement de serrure" },
  { link: "/reproduction-cle", text: "Reproduction de clés" },
  { link: "/porte-blinde", text: "Porte blindée" },
  { link: "/porte-hall", text: "Porte de hall" },
  { link: "/digicode-interphone", text: "Digicode - Interphone" },
  { link: "/porte-box", text: "Porte box" },
  { link: "/grille-defense", text: "Grille de défense" },
];

const contactLinks = [
  {
    link: "tel:+33142289944",
    icon: <Tel />,
    text: "01 42 28 99 44",
  },
  {
    link: "mailto:serrurerie.guymoquet@gmail.com",
    icon: <Mail />,
    text: "serrurerie.guymoquet@gmail.com",
  },
  {
    link: "https://www.google.com/maps/place/SERRURERIE+GUY+MOQUET+-+Picard+Serrures/@48.893253,2.325612,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66e54c85c1095:0x8a3e9f7df9b5b970!8m2!3d48.893253!4d2.325612!16s%2Fg%2F11g698b840?entry=ttu&g_ep=EgoyMDI0MTAxNC4wIKXMDSoASAFQAw%3D%3D",
    icon: <MapAdress />,
    text: "17 Rue de La Jonquière, 75017 Paris",
  },
  {
    link: "https://www.instagram.com/serrurerie_guymoquet/?igshid=YmMyMTA2M2Y%3D",
    icon: <Instagram />,
    text: "serrurerie_guymoquet",
  },
  {
    link: "https://www.facebook.com/serrurerieguymoquet/",
    icon: <Facebook />,
    text: "Serrurerie Guy Moquet",
  },
  {
    link: "https://www.linkedin.com/in/st%C3%A9phane-baroukh-780760209/",
    icon: <Linkedin />,
    text: "Stéphane Baroukh",
  },
  {
    link: "https://www.tiktok.com/@serrurerie_guy_moquet?_t=8ZgX0EMKP8o&_r=1",
    icon: <TikTok />,
    text: "serrurerie_guy_moquet",
  },
];

function DstFooter() {
  return (
    <>
      <div className="d-flex align-items-center mt-4 black-background white-font">
        <div className="container">
          <div className="container-fluid">
            <Row className="my-4">
              <Col
                lg
                className="d-sm-flex flex-row-reverse flex-lg-column justify-content-between mb-2 mb-sm-4 mb-lg-0"
              >
                <div className="d-sm-flex flex-sm-column d-lg-block px-sm-2 px-lg-0">
                  <div className="mt-2 mt-lg-0">
                    Serrurerie Guy Môquet est là pour répondre à tous vos
                    besoins en matière de sûreté, proposant également une gamme
                    étendue de services de serrurerie à Paris 17 et en
                    Île-de-France.
                  </div>
                  <div className="mt-2 mt-lg-4">
                    Installateur Agréé Picard Serrures à Paris
                  </div>
                </div>
                <div className="align-self-lg-center align-self-xl-start text-left">
                  <img
                    src="../../assets/images/picard-blanc.webp"
                    alt="logo picard"
                  />
                </div>
              </Col>
              <Col sm="5" md="6" lg="4" className="mb-2 mb-sm-0">
                <ul className="menu-footer list-inline">
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link}>{item.text}</Link>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col sm="7" md="6" lg="4" className="mb-2 mb-sm-0">
                <ul className="menu-footer list-inline">
                  {contactLinks.map((icon, index) => (
                    <li className="menu-list" key={index}>
                      <IconLink
                        link={icon.link}
                        icon={icon.icon}
                        text={icon.text}
                      />
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center dark-gray-background white-font">
        <div className="container">
          <div className="container-fluid py-3 text-center">
            © {year} Serrurerie Guy Môquet Tous droits réservés
            <span className="mx-3">-</span>
            <Link to={"/mentions-legales/"}>Mentions légales </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default DstFooter;
