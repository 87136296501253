import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import DstButton from "../../components/DstButton/DstButton";
import { Helmet } from "react-helmet";

const DigicodeInterphone = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Porte de Hall et Boîtes aux Lettres - Serrurerie Guy Môquet Paris 17
        </title>
        <meta
          name="description"
          content="Découvrez nos portes de hall en aluminium ou en acier et nos services d'installation de boîtes aux lettres sur mesure pour particuliers, professionnels et syndics de copropriété en Île-de-France."
        />
        <link
          rel="canonical"
          href="https://serrurerie-guymoquet.fr/digicode-interphone/"
        />
      </Helmet>
      <section className="container my-5">
        <div className="container-fluid">
          <Row className="d-flex flex-column-reverse flex-md-row">
            <Col className="align-self-center text-center text-md-start">
              <div>
                <img
                  src="../../assets/images/porte-hall.webp"
                  alt="porte blindé"
                  className="img-fluid"
                />
              </div>
              <DstButton
                btnClass="mt-3 d-md-none"
                value="Contactez-nous"
                clickFunction={() => navigate("/contact/")}
              />
            </Col>
            <Col md="8">
              <h2 className="line-title">Porte de hall</h2>
              <p>
                Les portes d'entrée de votre bâtiment peuvent être en aluminium
                ou en acier selon vos besoins.
              </p>
              <p>
                Les portes en aluminium sont principalement installées dans des
                bâtiments résidentiels où l'esthétique est plus importante que
                la sécurité. Certaines portes sont isolées, ce qui vous permet
                d'économiser de l'énergie et d'améliorer le confort dans le hall
                de l'immeuble.
              </p>
              <p>
                Les ventouses et les ferme-portes étant intégrés à la structure,
                <strong>
                  {" "}
                  les portes permettent l'intégration de tout type de contrôle
                  d'accès.
                </strong>{" "}
                <br />
                Disponible dans une variété de couleurs RAL ou anodisé.
                Différents types de verre sont recommandés : antivol (44² ou
                55²), double vitrage, etc.
              </p>
              <p>
                Les portes des halls peuvent être équipées de poignées demi-lune
                peintes ou de poignées en acier inoxydable. Les boutons de
                sortie peuvent également être insérés sur les cadres de porte.
                Les réservations pour les interphones peuvent également être
                conseillées.
              </p>
              <DstButton
                btnClass="mt-3 d-none d-md-block"
                value="Contactez-nous"
                clickFunction={() => navigate("/contact/")}
              />
            </Col>
          </Row>
        </div>
      </section>
      <section className="container my-5">
        <div className="container-fluid">
          <h2 className="line-title">Boîtes aux lettres</h2>
          <p>
            La serrurerie Guy Môquet vous recommande l'installation d'une
            <strong> batterie de boîte aux lettres.</strong> <br />
            Nous nous déplaçons en Île-de-France au service des particuliers,
            des professionnels et des syndics de copropriété. <br />
            Peu importe le style de boîte aux lettres que vous souhaitez, nous
            ferons de notre mieux pour vous satisfaire. Nous nous adaptons à la
            configuration de votre bâtiment.
          </p>
          <div className="text-center">
            <img
              src="../../assets/images/boite-lettre.webp"
              alt="porte blindé"
              className="img-fluid"
            />
          </div>
          <p className="mt-2 text-md-center">
            Vous pouvez 
            <Link className="orange-link" to={"/contact"}>
              faire appel à notre équipe
            </Link>
             pour installer une boîte aux lettres entièrement intégrée pour un
            revêtement mural parfait.
            <br />
            Pour plus de sécurité, nous proposons des boîtes aux lettres
            antivol.
          </p>
        </div>
        <div className="text-center mt-4">
          <DstButton
            value="Contactez-nous"
            clickFunction={() => navigate("/contact/")}
          />
        </div>
      </section>
    </React.Fragment>
  );
};

export default DigicodeInterphone;
